import {
  SelectionGroup,
  SelectionItem,
} from "../../Components/Selection/Selection";
import {
  ArrowIconRent,
  ArrowToLeft,
  ArrowToRightIcon,
  LightIcon,
  NUSDIcon,
} from "../../AssetComponents/Images";

import { Popover } from "react-tiny-popover";
import { useEffect, useState } from "react";
import UAE from "../../assets/images/Perps/UAE.png";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchBox } from "../../Components/Search/SearchBox";

export const Vaults = () => {
  const [showOrderMenu, setShowOrderMenu] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="my-[20px] mx-auto w-[80vw] p-[20px] rounded-[8px] font-normal">
      <div className="w-full flex items-center justify-end">
        <SearchBox />
      </div>

      <div className="w-full bg-white mt-[20px] p-[24px] rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
        <>
          <div className="flex items-center">
            <svg
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_dd_2523_693)">
                <rect
                  x="11"
                  y="11"
                  width="40"
                  height="40"
                  rx="20"
                  fill="#5B1DEE"
                />
                <rect
                  x="11"
                  y="11"
                  width="40"
                  height="40"
                  rx="20"
                  fill="url(#paint0_linear_2523_693)"
                  fill-opacity="0.18"
                />
                <g filter="url(#filter1_d_2523_693)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22 36V30.1C22 28.733 22.6214 27.4401 23.6889 26.5861L28.1889 22.9861C29.8323 21.6713 32.1677 21.6713 33.8111 22.9861L38.3111 26.5861C39.3786 27.4401 40 28.733 40 30.1V36C40 38.2091 38.2091 40 36 40H26C23.7909 40 22 38.2091 22 36Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22 36V30.1C22 28.733 22.6214 27.4401 23.6889 26.5861L28.1889 22.9861C29.8323 21.6713 32.1677 21.6713 33.8111 22.9861L38.3111 26.5861C39.3786 27.4401 40 28.733 40 30.1V36C40 38.2091 38.2091 40 36 40H26C23.7909 40 22 38.2091 22 36Z"
                    stroke="#202020"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <path
                  d="M27 34L29.5 31.5L32 34L35 31"
                  stroke="#202020"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_dd_2523_693"
                  x="0.333331"
                  y="0.333331"
                  width="61.3333"
                  height="61.3333"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="2.66667" dy="2.66667" />
                  <feGaussianBlur stdDeviation="4" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.733333 0 0 0 0 0.764706 0 0 0 0 0.807843 0 0 0 0.6 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2523_693"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="-2.66667" dy="-2.66667" />
                  <feGaussianBlur stdDeviation="4" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.992157 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_2523_693"
                    result="effect2_dropShadow_2523_693"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_2523_693"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter1_d_2523_693"
                  x="21.25"
                  y="21.25"
                  width="20.5"
                  height="20.5"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="1" dy="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.12549 0 0 0 0 0.12549 0 0 0 0 0.12549 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2523_693"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2523_693"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_2523_693"
                  x1="31"
                  y1="11"
                  x2="31"
                  y2="36.8333"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" />
                  <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>

            <div className="text-[24px] font-medium">Vaults</div>
          </div>
          <div className="text-[#959595] flex gap-[4px] items-center">
            APY calculated using data from the past 7 days.
          </div>
        </>

        <div className="flex items-center justify-between my-[20px]">
          <div className="text-[24px] font-medium">10 markets</div>
        </div>
        <div className="w-full">
          <div className="flex items-center gap-2 mb-2">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2655_12202)">
                <path
                  d="M31.625 16C31.625 24.6295 24.6295 31.625 16 31.625C7.37055 31.625 0.375 24.6295 0.375 16C0.375 7.37055 7.37055 0.375 16 0.375C24.6295 0.375 31.625 7.37055 31.625 16Z"
                  fill="#5B1DEE"
                />
                <path
                  d="M31.625 16C31.625 24.6295 24.6295 31.625 16 31.625C7.37055 31.625 0.375 24.6295 0.375 16C0.375 7.37055 7.37055 0.375 16 0.375C24.6295 0.375 31.625 7.37055 31.625 16Z"
                  fill="url(#paint0_linear_2655_12202)"
                  fill-opacity="0.18"
                />
                <path
                  opacity="0.1"
                  d="M9.08984 8.28531L16.4825 2.125L23.8751 8.28531H9.08984Z"
                  fill="white"
                />
                <path
                  opacity="0.1"
                  d="M23.8751 8.2854L16.4825 14.446L9.08984 8.2854H23.8751Z"
                  fill="white"
                />
                <path
                  opacity="0.1"
                  d="M23.8731 20.6063L16.4805 14.446L23.8731 8.2854V20.6063Z"
                  fill="white"
                />
                <path
                  d="M12.5425 9.15286C12.6488 8.82529 12.755 8.49801 12.8616 8.16981C12.5816 7.96624 12.3022 7.76329 12.0234 7.56065C12.0241 7.5591 12.0241 7.55879 12.0244 7.55847C12.0247 7.55816 12.025 7.55785 12.0253 7.55785C12.0262 7.55755 12.0272 7.55755 12.0281 7.55755C12.3712 7.55755 12.7144 7.55724 13.0578 7.55724C13.0581 7.55724 13.0588 7.55692 13.0611 7.55599C13.1669 7.23027 13.2731 6.90331 13.3791 6.57636C13.3804 6.57636 13.3806 6.57605 13.3813 6.57605C13.3816 6.57605 13.3823 6.57605 13.3825 6.57636C13.3831 6.5776 13.3838 6.57885 13.3844 6.58009C13.4901 6.90519 13.5958 7.22996 13.7018 7.55506C13.7018 7.55537 13.7024 7.55567 13.7033 7.55724C14.0476 7.55724 14.393 7.55724 14.7379 7.55724C14.7385 7.55879 14.7385 7.5591 14.7385 7.55972C14.7385 7.56002 14.7383 7.56065 14.7379 7.56096C14.4595 7.7636 14.181 7.96594 13.901 8.16981C13.9268 8.25155 13.9538 8.33329 13.9805 8.41504C14.0073 8.49709 14.0336 8.57914 14.0604 8.66119C14.0871 8.74324 14.1139 8.82529 14.1403 8.90734C14.167 8.98907 14.1934 9.07113 14.2176 9.15473C13.9391 8.9524 13.661 8.75008 13.3816 8.54744C13.1029 8.75008 12.8244 8.9524 12.5459 9.15473C12.5444 9.15411 12.5434 9.15349 12.5425 9.15286Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M20.819 21.7569L17.6401 24.303L13.2803 27.7954L10.3672 25.368L11.6781 24.303L17.8534 19.2852L20.819 21.7569Z"
                  fill="white"
                />
                <path
                  d="M23.8754 24.302L16.4831 30.4626L13.2812 27.7944L17.6411 24.302L20.8199 21.7559L23.8754 24.302Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M17.8537 19.2852L11.6785 24.3031L10.3675 25.3681L9.08984 24.3031L16.4824 18.1425L17.8537 19.2852Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M12.0017 14.4087L9.08984 16.6726V11.9821L12.0017 14.4087Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M14.8949 16.8187L9.08984 21.587V16.6721L12.0017 14.4082L14.8949 16.8187Z"
                  fill="white"
                />
                <path
                  d="M16.4824 18.1415L9.08984 24.3021V21.5866L14.8949 16.8184L16.4824 18.1415Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M15.2924 6.7819C15.3988 6.45432 15.5051 6.12705 15.6117 5.79885C15.3317 5.59528 15.0522 5.39233 14.7734 5.18969C14.7741 5.18813 14.7741 5.18783 14.7743 5.18752C14.7747 5.1872 14.7749 5.18689 14.7753 5.18689C14.7762 5.18658 14.7772 5.18658 14.7781 5.18658C15.1212 5.18658 15.4643 5.18627 15.8078 5.18627C15.8081 5.18627 15.8087 5.18595 15.8112 5.18503C15.9168 4.85932 16.0232 4.53235 16.1292 4.20539C16.1304 4.20539 16.1307 4.20508 16.1313 4.20508C16.1317 4.20508 16.1322 4.20508 16.1326 4.20539C16.1332 4.20664 16.1338 4.20788 16.1344 4.20913C16.2401 4.53422 16.3458 4.859 16.4517 5.18409C16.4517 5.1844 16.4523 5.18472 16.4533 5.18627C16.7977 5.18627 17.1429 5.18627 17.4879 5.18627C17.4886 5.18783 17.4886 5.18813 17.4886 5.18875C17.4886 5.18907 17.4883 5.18969 17.4879 5.19C17.2094 5.39264 16.9309 5.59497 16.6509 5.79885C16.6768 5.88059 16.7038 5.96233 16.7306 6.04407C16.7573 6.12612 16.7837 6.20817 16.8104 6.29022C16.8372 6.37227 16.8638 6.45432 16.8903 6.53637C16.9171 6.61812 16.9434 6.70017 16.9677 6.78377C16.6892 6.58144 16.4111 6.3791 16.1317 6.17647C15.8528 6.3791 15.5743 6.58144 15.2959 6.78377C15.2943 6.78283 15.2934 6.78253 15.2924 6.7819Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M15.2924 11.5631C15.3988 11.2356 15.5051 10.9083 15.6117 10.5801C15.3317 10.3765 15.0522 10.1736 14.7734 9.97093C14.7741 9.96938 14.7741 9.96907 14.7743 9.96875C14.7747 9.96844 14.7749 9.96813 14.7753 9.96813C14.7762 9.96782 14.7772 9.96782 14.7781 9.96782C15.1212 9.96782 15.4643 9.96752 15.8078 9.96752C15.8081 9.96752 15.8087 9.9672 15.8112 9.96627C15.9168 9.64055 16.0232 9.31359 16.1292 8.98663C16.1304 8.98663 16.1307 8.98633 16.1313 8.98633C16.1317 8.98633 16.1322 8.98633 16.1326 8.98663C16.1332 8.98788 16.1338 8.98912 16.1344 8.99037C16.2401 9.31545 16.3458 9.64024 16.4517 9.96533C16.4517 9.96564 16.4523 9.96595 16.4533 9.96752C16.7977 9.96752 17.1429 9.96752 17.4879 9.96752C17.4886 9.96907 17.4886 9.96938 17.4886 9.96999C17.4886 9.9703 17.4883 9.97093 17.4879 9.97124C17.2094 10.1739 16.9309 10.3762 16.6509 10.5801C16.6768 10.6618 16.7038 10.7436 16.7306 10.8253C16.7573 10.9074 16.7837 10.9894 16.8104 11.0715C16.8372 11.1535 16.8638 11.2356 16.8903 11.3176C16.9171 11.3994 16.9434 11.4814 16.9677 11.565C16.6892 11.3627 16.4111 11.1604 16.1317 10.9577C15.8528 11.1604 15.5743 11.3627 15.2959 11.565C15.2943 11.5641 15.2934 11.5635 15.2924 11.5631Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M18.0229 9.15286C18.1293 8.82529 18.2355 8.49801 18.3422 8.16981C18.0622 7.96624 17.7827 7.76329 17.5039 7.56065C17.5045 7.5591 17.5045 7.55879 17.5049 7.55847C17.5052 7.55816 17.5054 7.55785 17.5058 7.55785C17.5067 7.55755 17.5077 7.55755 17.5085 7.55755C17.8517 7.55755 18.1948 7.55724 18.5383 7.55724C18.5385 7.55724 18.5392 7.55692 18.5417 7.55599C18.6473 7.23027 18.7537 6.90331 18.8597 6.57636C18.8609 6.57636 18.8612 6.57605 18.8618 6.57605C18.8622 6.57605 18.8628 6.57605 18.863 6.57636C18.8637 6.5776 18.8643 6.57885 18.8649 6.58009C18.9705 6.90519 19.0763 7.22996 19.1822 7.55506C19.1822 7.55537 19.1828 7.55567 19.1838 7.55724C19.5282 7.55724 19.8734 7.55724 20.2184 7.55724C20.219 7.55879 20.219 7.5591 20.219 7.55972C20.219 7.56002 20.2188 7.56065 20.2184 7.56096C19.9399 7.7636 19.6614 7.96594 19.3814 8.16981C19.4073 8.25155 19.4343 8.33329 19.461 8.41504C19.4878 8.49709 19.5142 8.57914 19.5409 8.66119C19.5677 8.74324 19.5943 8.82529 19.6208 8.90734C19.6475 8.98907 19.6739 9.07113 19.6982 9.15473C19.4197 8.9524 19.1415 8.75008 18.8622 8.54744C18.5833 8.75008 18.3048 8.9524 18.0264 9.15473C18.0248 9.15411 18.0239 9.15349 18.0229 9.15286Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M18.0229 13.8346C18.1293 13.507 18.2355 13.1798 18.3422 12.8516C18.0622 12.648 17.7827 12.4451 17.5039 12.2424C17.5045 12.2409 17.5045 12.2406 17.5049 12.2403C17.5052 12.2399 17.5054 12.2396 17.5058 12.2396C17.5067 12.2393 17.5077 12.2393 17.5085 12.2393C17.8517 12.2393 18.1948 12.239 18.5383 12.239C18.5385 12.239 18.5392 12.2387 18.5417 12.2378C18.6473 11.912 18.7537 11.5851 18.8597 11.2581C18.8609 11.2581 18.8612 11.2578 18.8618 11.2578C18.8622 11.2578 18.8628 11.2578 18.863 11.2581C18.8637 11.2594 18.8643 11.2606 18.8649 11.2619C18.9705 11.587 19.0763 11.9117 19.1822 12.2368C19.1822 12.2371 19.1828 12.2375 19.1838 12.239C19.5282 12.239 19.8734 12.239 20.2184 12.239C20.219 12.2406 20.219 12.2409 20.219 12.2415C20.219 12.2418 20.2188 12.2424 20.2184 12.2427C19.9399 12.4454 19.6614 12.6478 19.3814 12.8516C19.4073 12.9333 19.4343 13.015 19.461 13.0968C19.4878 13.1789 19.5142 13.2609 19.5409 13.343C19.5677 13.425 19.5943 13.507 19.6208 13.5891C19.6475 13.6709 19.6739 13.7529 19.6982 13.8365C19.4197 13.6341 19.1415 13.4319 18.8622 13.2293C18.5833 13.4319 18.3048 13.6341 18.0264 13.8365C18.0248 13.8355 18.0239 13.8353 18.0229 13.8346Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M20.8433 11.487C20.9496 11.1594 21.0558 10.8321 21.1625 10.5039C20.8825 10.3004 20.6031 10.0974 20.3242 9.89477C20.3248 9.89322 20.3248 9.89291 20.3252 9.89259C20.3255 9.89228 20.3258 9.89197 20.3261 9.89197C20.3271 9.89166 20.328 9.89166 20.329 9.89166C20.6721 9.89166 21.0152 9.89134 21.3586 9.89134C21.359 9.89134 21.3595 9.89103 21.362 9.89011C21.4677 9.56439 21.574 9.23743 21.68 8.91047C21.6812 8.91047 21.6815 8.91016 21.6821 8.91016C21.6825 8.91016 21.6831 8.91016 21.6833 8.91047C21.684 8.91172 21.6846 8.91296 21.6852 8.91421C21.791 9.23929 21.8966 9.56408 22.0026 9.88917C22.0026 9.88948 22.0032 9.88979 22.0041 9.89134C22.3485 9.89134 22.6938 9.89134 23.0387 9.89134C23.0393 9.89291 23.0393 9.89322 23.0393 9.89383C23.0393 9.89414 23.0391 9.89477 23.0387 9.89508C22.7603 10.0977 22.4818 10.3 22.2018 10.5039C22.2276 10.5857 22.2546 10.6674 22.2813 10.7491C22.3081 10.8312 22.3345 10.9132 22.3612 10.9953C22.388 11.0773 22.4147 11.1594 22.4411 11.2415C22.4678 11.3232 22.4942 11.4052 22.5185 11.4888C22.24 11.2865 21.9618 11.0842 21.6825 10.8815C21.4037 11.0842 21.1252 11.2865 20.8467 11.4888C20.8448 11.4882 20.8442 11.4876 20.8433 11.487Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M20.7378 16.2115C20.844 15.8839 20.9504 15.5566 21.0569 15.2285C20.7769 15.0249 20.4975 14.8219 20.2188 14.6193C20.2194 14.6178 20.2194 14.6174 20.2196 14.6171C20.22 14.6168 20.2202 14.6165 20.2206 14.6165C20.2215 14.6161 20.2224 14.6161 20.2234 14.6161C20.5665 14.6161 20.9096 14.6159 21.253 14.6159C21.2534 14.6159 21.254 14.6156 21.2565 14.6146C21.3621 14.2889 21.4684 13.962 21.5744 13.635C21.5756 13.635 21.576 13.6348 21.5766 13.6348C21.5769 13.6348 21.5775 13.6348 21.5779 13.635C21.5785 13.6363 21.579 13.6375 21.5796 13.6388C21.6854 13.9639 21.791 14.2886 21.897 14.6138C21.897 14.614 21.8976 14.6144 21.8985 14.6159C22.2429 14.6159 22.5883 14.6159 22.9333 14.6159C22.9339 14.6174 22.9339 14.6178 22.9339 14.6184C22.9339 14.6186 22.9335 14.6193 22.9333 14.6196C22.6548 14.8223 22.3763 15.0246 22.0963 15.2285C22.122 15.3103 22.149 15.3919 22.1758 15.4736C22.2025 15.5558 22.2289 15.6378 22.2556 15.7199C22.2824 15.8019 22.3091 15.8839 22.3355 15.966C22.3623 16.0478 22.3886 16.1298 22.4129 16.2134C22.1345 16.011 21.8563 15.8088 21.5769 15.6061C21.2981 15.8088 21.0196 16.011 20.7411 16.2134C20.7396 16.2125 20.7386 16.2119 20.7378 16.2115Z"
                  fill="#EFEFEF"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_2655_12202"
                  x1="16"
                  y1="0.375"
                  x2="16"
                  y2="20.5573"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" />
                  <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <clipPath id="clip0_2655_12202">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="font-medium text-[18px]">gUSD</div>
            <div className="bg-[#E8FFF2] text-[#38A569] text-[14px] px-2 rounded-sm">
              68.11% APY
            </div>
          </div>
          <table className="w-full">
            <tr className="text-[#8A8A8A] text-[14px] align-middle border-[#E3E3E3]">
              <td>Deposit</td>
              <td>TVL</td>
              <td>gUSD Price</td>
            </tr>
            <tr
              onClick={() => navigate("/perps/vaults/detail")}
              className="hover:bg-[#dddddd] cursor-pointer align-middle h-[50px] border-b-[1px] border-[#E3E3E3]"
            >
              <td className="px-[10px]">
                <div className="flex gap-[4px] items-center">
                  <NUSDIcon />
                  <div>NUSD</div>
                </div>
              </td>
              <td>
                <div className="flex gap-[4px] items-center">
                  <div>16,516</div>
                  <NUSDIcon />
                </div>
              </td>
              <td>
                <div className="flex gap-[4px] items-center">
                  <div>1.00241</div>
                  <NUSDIcon />
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className="w-full items-center justify-between flex mt-2">
          <div>1-3 of 3</div>
          <div className="flex items-center gap-1">
            <ArrowToLeft />
            <div>1</div>
            <ArrowToRightIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
