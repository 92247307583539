import { queryContract } from "./Contract";
import { updateNFTStore } from "../../ReduxSlices/NFTSlice";
import { api } from "./Api";

export const truncateWalletAddress = (address, forward = 5, backward = 4) => {
  if (address)
    return (
      address.toString().substring(0, forward) +
      "..." +
      address.toString().substring(address.toString().length - backward)
    );
};

export const updateToken = async (data, endpt, dispatch) => {
  const res = await queryContract(
    data.contract,
    {
      all_nft_info: {
        token_id: data.token_id,
      },
    },
    endpt
  );

  if (!res) return;
  let metaData = {
    images: [],
  };

  for (let i = 0; i < res.info.extension.image.length; i++) {
    metaData.images.push(res.info.extension.image[i].value);
  }
  for (let i = 0; i < res.info.extension.attributes.length; i++) {
    try {
      metaData[res.info.extension.attributes[i].key] = JSON.parse(
        res.info.extension.attributes[i].value
      );
    } catch (error) {
      metaData[res.info.extension.attributes[i].key] =
        res.info.extension.attributes[i].value;
    }
  }
  const short = await queryContract(
    data.contract,
    {
      nft_info_short_term_rental: {
        token_id: data.token_id,
      },
    },
    endpt
  );
  dispatch(
    updateNFTStore({
      token_id: data.token_id,
      metaData: metaData,
      short: short,
      access: res.access,
    })
  );
};

export const getProfileFromWallet = async (wallet) => {
  const profile = await api("profile/getProfile", {
    walletAddress: wallet,
  });
  return profile;
};

export const getTime = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const timeString = hours + ":" + minutes + " " + amOrPm;
  return timeString;
};

export const getDay = (date) => {
  try {
    const monthString = new Intl.DateTimeFormat("en-US", {
      month: "short",
    }).format(date);

    const day = ("0" + date.getDate()).slice(-2);
    let formattedDate = monthString + " " + day;
    const currentTime = new Date();
    if (
      currentTime.getFullYear() +
        currentTime.getMonth() +
        currentTime.getDate() ===
      date.getFullYear() + date.getMonth() + date.getDate()
    )
      return "Today";

    return formattedDate;
  } catch (error) {
    return null;
  }
};

export const getChatId = async (renter, owner, nftId, mode) => {
  return await api("chat/getChatId", {
    renter: renter,
    owner: owner,
    nftId: nftId,
    mode: mode,
  });
};

export const getIdFromHash = async (hash) => {
  return await api("tx/fetchId", {
    hash: hash,
  });
};
